@import '../../breakpoints.scss';

.output{
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 0.5rem auto;
    width: 100%;
    max-width: 900px;
}


.output header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}

.output header h1{
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 48px;
    text-align: center;
    color: #646464;
    align-self: flex-end;

    @include media("<phone"){
        font-size: 25px;
    }

    @include media(">phone", "<tablet"){
        font-size: 30px;
    }
}


.body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
}

.individual-resources{
display: flex;
flex-direction: row;
justify-content: space-evenly;

    @include media("<phone"){
        flex-direction: column;
    }

    @include media(">phone", "<tablet"){
        flex-direction: column;
    }

    .output-body{
        margin: auto;
        max-width: 600px;
    }
}

.resource-main,
.resource-target{
    margin: 0 0.5rem;
    width: 49%;

    @include media("<phone"){
        width: 100%;
        margin: 0.5rem auto;
    }

    @include media(">phone", "<tablet"){
        width: 100%;
        margin: 0.5rem auto;
    }
}

.resource-main-header,
.resource-target-header,
.resource-matched-header,
.resource-unmatched-header{
    display: flex;
    flex-direction: row;
    padding: 2rem 1.3rem;
    text-align: left;
    cursor: pointer;
    transition: 0.2s;
    background: #CEDEFF;
    border-radius: 3px 3px 0 0;
    color: #42526E;
    max-width: 883px;
    margin: auto;

    p{
        padding: 0 0.7rem;
    }

    img{
        transition: 0.2s;
    }
}

.dropdown{
    transform: rotateZ(90deg);
}

.resource-main-header,
.resource-target-header{
    padding: 2.5rem 4rem ;

    @include media("<phone"){
        padding: 2rem 2rem;
    }

    @include media(">phone", "<tablet"){
        padding: 2.2rem 2.5rem;
    }
}

.target-name,
.main-name{
    text-transform: capitalize;
}


.resource-main-header:hover,
.resource-target-header:hover,
.resource-matched-header:hover,
.resource-unmatched-header:hover{
    background: #8CB3FF;
    box-shadow: none;
    color: #42526E;
}

.resource-main-body,
.resource-target-body,
.resource-matched-body,
.resource-unmatched-body{
    display: none;
    margin: 0 auto;
    padding: 1rem;
    width: 100%;
    transition: 0.2s;
    background: #CEDEFF;
    max-width: 883px;
    margin: auto;

    .output-table{
        max-height: 400px;
        overflow: auto;

        @include media("<phone"){
            height: 300px;
            overflow: auto;
        }
    
        @include media(">phone", "<tablet"){
            height: 350px;
            overflow: auto;
        }
        thead{
            background: #8CB3FF;
        }
        tbody{
            background: #fff;
            tr{
                padding: 0 0.3rem;
            }
        }

        .kVkMWT,
        .dOgRgg {
            border-bottom: 0.2px solid #6464645b;
            padding: 4px 8px;
        }

        .ildPPD,
        .giuCBN,
        .jIqHDV,
        .cUEqMQ{
            padding: 10px 13px;
        }

        
    }
}


.open{
    display: block;

}

.comparison{
    .resource-matched-header,
    .resource-unmatched-header{
        margin-top: 0.8rem;
    }
}