@import '../../breakpoints.scss';

.cjr{
    display: flex;
    flex-direction: column;
    padding: 2rem;

    @include media("<phone"){
        padding: 0.6rem;
    }

    @include media(">phone", "<custom-view-2"){
        padding: 1rem;
    }

    header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        img{
            width: 100%;
            max-width: 122px;

            @include media("<phone"){
                width: 90px;
            }
        
            @include media(">phone", "<custom-view-2"){
                max-width: 110px;
            }
        }

        h1{
            width: 30%;
            font-style: normal;
            font-weight: normal;
            font-size: 35px;
            line-height: 48px;
            color: #646464;
        }
    }
}
  
  
  
main{
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
}

.input{
    width: 100%;
}

.info{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 500px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #646464;
    margin: 2rem;

    @include media("<phone"){
        display: none;
    }

    @include media(">phone", "<custom-view"){
        display: none;
    }

    img{
        align-self: flex-start;
    }
}
