@import '../../breakpoints.scss';

/* default class for fonts-face with icons */
.icon { font-family: 'IconFontRoleImg' !important; }


.input-form{
    width: 100%;
    max-width: 600px;
    margin: auto;
    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        .hosting-type-title{
            color: #6B778C;
        }

        .sc-AxmLO{
            margin: 1.5rem 0;

            input{
                background: #CEDEFF;
            }
        }
    }

    .radio-select{
        margin: 1.5rem 0;
        >div{
            background: #CEDEFF;
        }
    }

    button{
        margin: 1rem 0;
        width: fit-content;
        align-self: flex-end;
    }
}

.none{
    display: none;
}

.error{
    border-color: red;
    display: block;
    color: red;
}

.comparable-resources{
    h4{
        color: #6B778C;
    }
    .sc-fzqNJr{
        background: #CEDEFF;

        textarea{
            color: #000;
        }
    }

    .css-vxcmzt {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .comparable-resources-options{
        display: flex;
        flex: 1 0 auto;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 1.5rem 0;
        transition: 0.2s;

        @include media("<phone"){
            height: 215px;
            overflow: auto;
        }


        @include media(">phone","<tablet"){
            height: 215px;
            overflow: auto;
        }
    }

    .comparable-resources-option{
        display: flex;
        margin: 1rem;
        align-self: center;
        align-items: baseline;
        border-width: 0;
        box-sizing: border-box;
        font-size: inherit;
        font-weight: 500;
        outline: none !important;
        text-align: center;
        -webkit-text-decoration: none;
        text-decoration: none;
        white-space: nowrap;
        background: rgba(9,30,66,0.04);
        border-radius: 3px;
        color: #42526E;
        cursor: default;
        height: 2.2857142857142856em;
        line-height: 2.2857142857142856em;
        padding: 0 10px;
        transition: 0.1s ease-out,box-shadow 0.15s cubic-bezier(0.47,0.03,0.49,1.38);
        transition-duration: 0.1s,0.15s;
        vertical-align: middle;

        @include media("<phone"){
            margin: 0.3rem;
        }

        &:hover{
            background: rgba(39, 41, 44, 0.089);
            cursor: pointer;
        }
    }

    
}

.clicked{
    background: #253858 !important;
    color: #fff !important;
}
