@import '../../breakpoints.scss';

.output-page{
    display: flex;
    flex-direction: column;
}

.loading{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto;
    padding: 15rem 0;
}

.loading-shape{
    align-self: center;
    margin: 0 auto;
    i{
        width: 100px;
        height: 100px;
        display: inline-block;
        background: #0052CC;
        border-radius: 50%;

        @include media("<phone"){
            width: 60px;
        }
    
        @include media(">phone", "<custom-view-2"){
            max-width: 80px;
        }
    }
    i:nth-child(1){
        animation:loading2-ani1 1s ease-in-out infinite;
    
    }
    i:nth-child(2){
        background: #253858;
        margin-left: -10px;
        animation:loading2-ani1 1s ease-in-out 0.5s infinite;
    }
    @keyframes loading2-ani1{
        70%{
            transform:scale(0.5);
        }
    }

}
 
.loading-text{
    text-align: center;
    color: #253858;
    font-size: 18px;
    margin: 1rem auto;
    width: fit-content;
    animation: grow 3s infinite;

    @keyframes grow {
        0%{
            transform: scale(1);
        }
        50%{
            transform: scale(1.05);
        }
        100%{
            transform: scale(1);
        }
    }
}


.navigation{
    display: block;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    

    .logo{
        display: block;
        img{
            padding: 2rem;
            width: 100%;
            max-width: 200px;

            @include media("<phone"){
                width: 150px;
            }
        
            @include media(">phone", "<custom-view-2"){
                max-width: 170px;
            }
        }
    }

    .options{
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        padding: 1rem;
        margin:1rem;

        @include media("<phone"){
            padding: 1rem 0.4rem;
            margin: 0.5rem 0;
        }

        @include media(">phone", "<tablet"){
            padding: 0.8rem;
            margin: 0.5rem;
        }

        .home-link{
            display: flex;
            flex-direction: column;
            margin: 0 0.6rem;
            padding: 0 0.6rem;
            text-decoration:none;
            &:hover{
                img{
                    transform: translateY(-5px);
                }
            }

            span{
                display: block;
                align-self: center;
                margin: 0 0.3rem;
                color: #000;
                width: 100%;
                margin: auto;
                transition: 0.18s;
            }

            img{
                width: 100%;
                align-self: center;
                max-width: 50px;
                margin: auto;
                transition: 0.2s;               
            }
        }

        .top-menu{
            display: flex;
            justify-content: flex-end;

            ul{
                display: flex;
                flex-direction: row;
                list-style-type: none;

                @include media("<phone"){
                    display: none;
                }
    
                @include media(">phone", "<tablet"){
                    display: none;
                }
            }
            
            .top-menu-icon{
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;
                padding:0.2rem 0.5rem;
                transition: 0.2s;
            }
            .resource-icon{
                margin: auto;
                transition: 0.2s;
                svg{
                    fill-opacity: 0.25;
                    transition: 0.2s;
                }
            }
            .active-option-icon{
                .menu-text{
                    padding-bottom: 0.3rem;
                    border-bottom: 1px solid #000;
                }
                .resource-icon{
                    transform: scale(1.2);
                    svg{
                        fill-opacity: 1 !important;
                    }
                }
            }
        }
        
    }

}



.menu-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 0.5rem 1rem;
    align-self: center;
    cursor: pointer;
    
    img{
        margin: auto;
        width: 100%;
        max-width: 30px;
    }

}

.menu{
    display: none;
    flex-direction: row;
    position: absolute;
    top: 240px;
    right: 0;
    z-index: 3;

    

    ul{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background: #8CB3FF;
        border: 0.2px solid #FFFFFF;

        @include media("<phone"){
            width: 100%;
            max-width: 240px;
        }
    
        @include media(">phone", "<tablet"){
            width: 100%;
            max-width: 260px;
        }
    }

    li{
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style: none;
        padding: 2rem 3rem;
        text-align: center;
        transition: 0.2s;
        cursor: pointer;
        border: 0.1px solid #FFFFFF;
        color: #fff;

        &:hover{
            background: #003399;
        }
        img{
            width: 100%;
            max-width: 22px;
        }

        span{
            margin: 0 0.4rem;
        }
    }
}

.show-menu{
    display: block;
    animation: menuEffect 0.3s ;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;

    @include media("<phone"){
        max-height: 400px;
    }

    @include media(">phone", "<tablet"){
        max-height: 500px;
    }
    
}


.active-option{
    background: #003399;
}


.main-output{
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 2rem auto;
    padding: 2rem 0;
    width: 100%;
    min-height: 600px;

    @include media("<phone"){
        padding: 0.5rem;
    }

    @include media(">phone", "<custom-view"){
        padding: 2rem;
    }
}


.resource-body{
    display: none;
    flex-direction: column;
    justify-content: center;
}

.active{
    display: flex;
}

footer{
    display: flex;
    position: relative;
    bottom: 0;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 1rem;
    width: 100%;
    background: #8CB3FF;
    p{
        margin: auto;
        width: auto;
    }
}

@keyframes menuEffect{
    0%{
        display: none;
        transform: translateY(-10px);
    }

    100%{
        display: flex;
        transform: translateY(0px);
    }
}

