.not-found{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    margin: 4rem auto;
    padding: 2rem 1rem;
}

.not-found-text{
    padding: 0.5rem;
    margin: 1.5rem auto;
    a{
        margin: 0 0.2rem;
    }
}